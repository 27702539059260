<template>
    <transition appear enter-active-class="animated fadeIn">
        <destinology v-if="destinology"/>
        <broadway-travel v-if="broadwaytravel"/>
        <inspired-luxury-escapes v-if="inspiredluxuryescapes"/>
        <premier-ni v-if="premierni"/>
        <balkan-holidays v-if="balkanholidays"/>
    </transition>
</template>

<script>
    import Destinology from '../components/affiliates/Destinology';
    import BroadwayTravel from '../components/affiliates/BroadwayTravel';
    import InspiredLuxuryEscapes from '../components/affiliates/InspiredLuxuryEscapes';
    import PremierNi from '../components/affiliates/PremierNI';
    import BalkanHolidays from '../components/affiliates/BalkanHolidays';

    import { mapActions, mapGetters } from "vuex";

    export default {
        name: "Affiliates",
        components: {
            Destinology,
            BroadwayTravel,
            BalkanHolidays,
            InspiredLuxuryEscapes,
            PremierNi
        },
        data() {
            return {
                destinology: false,
                broadwaytravel: false,
                inspiredluxuryescapes: false,
                premierni: false,
                balkanholidays: false
            }
        },
        methods: {
            ...mapActions({
                setAffiliation: 'setAffiliation',
            }),
          getAffiliation ()  {
            const name = 'profile' + "=";
            const cDecoded = decodeURIComponent(document.cookie); //to be careful
            const cArr = cDecoded .split('; ');
            let res;
            cArr.forEach(val => {
              if (val.trim().indexOf(name) === 0) res = val.substring(name.length);
            })
            return res;
          }
        },
        computed: {
            ...mapGetters({
                affiliation: 'affiliation',
            })
        },
        created() {
            let affiliate = null;
            if (this.$route.params.affiliate !== "") {
                this.setAffiliation(this.$route.params.affiliate);
                affiliate = this.$route.params.affiliate.toLowerCase();
            }
            if (affiliate == null) {
              affiliate = this.getAffiliation().toLowerCase();
            }
            if (affiliate === 'destinology') {
                this.destinology = true;
            } else if (affiliate === 'broadwaytravel') {
                this.broadwaytravel = true;
            } else if (affiliate === 'inspiredluxuryescapes') {
                this.inspiredluxuryescapes = true;
            } else if (affiliate === 'premierni') {
                this.premierni = true;
            } else if (affiliate === 'balkanholidays') {
                this.balkanholidays = true;
            } else {
                this.$router.push({name: 'pageNotFound'})
            }
        }
    }
</script>